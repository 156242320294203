import Cookies from 'js-cookie';
import { i18nNext, SET_LANGUAGE } from './actionTypes';

const defaultState = {
  isLoading: true,
  isError: false,
  translations: {},
  languages: { EN: 'English' },
  activeLanguage: Cookies.get('active-language') || 'EN',
};

export const i18n = (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_LANGUAGE:
      return {
        ...state,
        activeLanguage: payload,
      };
    case i18nNext.INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case i18nNext.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        translations: {},
        languages: { EN: 'English' },
      };
    case i18nNext.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        translations: payload.translations || {},
        languages: payload.languages || { EN: 'English' },
      };
    default:
      return state;
  }
};
