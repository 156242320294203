import { getInvestorTokenDetailsNext } from './actionTypes';

const defaultState = {
  isLoading: false,
  isError: false,
  errorType: '',
  token: {},
};

export const tokenDetails = (state = defaultState, { type, payload }) => {
  switch (type) {
    case getInvestorTokenDetailsNext.INIT:
      return {
        ...state,
        isLoading: true,
      };

    case getInvestorTokenDetailsNext.SUCCESS:
      return {
        ...state,
        token: payload,
        isLoading: false,
        errorType: '',
        isError: false,
      };

    case getInvestorTokenDetailsNext.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorType: payload.message,
      };

    default:
      return state;
  }
};
