import Cookies from 'js-cookie';

/* const EUCountries = [
  'BE', 'BG', 'CZ', 'DK', 'DE', 'DE', 'EE', 'IE', 'EL',
  'ES', 'FR', 'HR', 'IT', 'CY', 'LV', 'LT', 'LU', 'HU',
  'MT', 'NL', 'AT', 'PL', 'PT', 'RO', 'SI', 'SK', 'FI', 'SE']; */

export const MixPanel = {
  alias: (investorId) => {
    try {
      const { isAcceptedAnalyticsCookies } = JSON.parse(Cookies.get('cookies-usage') || '{"isAcceptedAnalyticsCookies": true}');
      if (isAcceptedAnalyticsCookies) {
        window.mixpanel.alias(investorId);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.info('Mixpanel is not active');
    }
  },
  identify: (uniqueIdentifier) => {
    try {
      const { isAcceptedAnalyticsCookies } = JSON.parse(Cookies.get('cookies-usage') || '{"isAcceptedAnalyticsCookies": true}');
      if (isAcceptedAnalyticsCookies && window.mixpanel.get_distinct_id() !== uniqueIdentifier) {
        window.mixpanel.identify(uniqueIdentifier);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.info('Mixpanel is not active');
    }
  },
  track: (event, data = {}) => {
    // console.log('@@', event, data);
    try {
      const { isAcceptedAnalyticsCookies } = JSON.parse(Cookies.get('cookies-usage') || '{"isAcceptedAnalyticsCookies": true}');
      if (isAcceptedAnalyticsCookies) {
        window.mixpanel.track(`nie-${event}`, data);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.info('Mixpanel is not active');
    }
  },
  getMixpanelId: () => {
    try {
      const { isAcceptedAnalyticsCookies } = JSON.parse(Cookies.get('cookies-usage') || '{"isAcceptedAnalyticsCookies": true}');
      if (isAcceptedAnalyticsCookies) {
        return window.mixpanel.get_distinct_id();
      }
      return '';
    } catch (e) {
      // eslint-disable-next-line no-console
      console.info('Mixpanel is not active');
      return '';
    }
  },
  resetMixPanel: () => {
    try {
      const { isAcceptedAnalyticsCookies } = JSON.parse(Cookies.get('cookies-usage') || '{"isAcceptedAnalyticsCookies": true}');
      if (isAcceptedAnalyticsCookies) {
        window.mixpanel.reset();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.info('Mixpanel is not active');
      return '';
    }
  },
};
