import { combineReducers } from 'redux';
import { modal } from './modal';
import { tfa } from './tfa';
import { options } from './options';

export const helpers = combineReducers({
  modal,
  tfa,
  options,
});
