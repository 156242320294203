import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import './App.scss';
import 'ladda/dist/ladda-themeless.min.css';
import store from './store';

const loading = () => (
  <div className="loader animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

const App = () => (
  <Provider store={store}>
    <HashRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  </Provider>
);

export default App;
