import { combineReducers } from 'redux';
import { opportunities } from './components/OpportunitiesPage/reducer';
import { opportunity } from './components/OpportunitiesDetailsPage/reducer';
import { agreement } from './components/SignAgreement/reducer';
import { depositAddresses } from './components/PaymentPage/reducer';
import { opportunityWizard } from './components/OpportunityWizard/reducer';

export const investment = combineReducers({
  opportunities,
  opportunity,
  agreement,
  depositAddresses,
  opportunityWizard,
});
