import { QUALIFICATION_QUESTIONS_NEXT, SUBMIT_QUALIFICATION_QUESTIONS_NEXT } from './actionTypes';

const defaultState = {
  isLoading: false,
  isError: false,
  sections: [],
};

export const investorQualification = (state = defaultState, { type, payload }) => {
  switch (type) {
    case QUALIFICATION_QUESTIONS_NEXT.INIT:
    case SUBMIT_QUALIFICATION_QUESTIONS_NEXT.INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case QUALIFICATION_QUESTIONS_NEXT.ERROR:
    case SUBMIT_QUALIFICATION_QUESTIONS_NEXT.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case QUALIFICATION_QUESTIONS_NEXT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        sections: payload,
      };
    case SUBMIT_QUALIFICATION_QUESTIONS_NEXT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    default:
      return state;
  }
};
