import { getInvestorDocumentsNext } from './actionTypes';

const defaultState = {
  isInitialized: false,
  isLoading: false,
  isError: false,
  errorType: '',
  documents: [],
};

export const documents = (state = defaultState, { type, payload }) => {
  switch (type) {
    case getInvestorDocumentsNext.INIT:
      return {
        ...state,
        isLoading: true,
      };

    case getInvestorDocumentsNext.SUCCESS:
      return {
        ...state,
        documents: payload,
        isLoading: false,
        isInitialized: true,
        errorType: '',
        isError: false,
      };

    case getInvestorDocumentsNext.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorType: payload.message,
      };

    default:
      return state;
  }
};
