import Cookies from 'js-cookie';
import { TFA_NOTIFICATION_SUCCESS } from './actionTypes';

const defaultState = {
  tfaNotification: !!Cookies.get('_tfaNotification') && JSON.parse(Cookies.get('_tfaNotification')),
  tfaEverUsed: !!Cookies.get('_tfaEverUsed') && JSON.parse(Cookies.get('_tfaEverUsed')),
};

export const tfa = (state = defaultState, { type, payload }) => {
  switch (type) {
    case TFA_NOTIFICATION_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newNotification = state.tfaNotification
        ? [...state.tfaNotification.filter((email) => email !== payload), payload]
        : [payload];
      Cookies.set('_tfaNotification', JSON.stringify(newNotification), { expires: 30, path: '/' });
      return {
        ...state,
        tfaNotification: newNotification,
      };

    default: return state;
  }
};
