import {
  GET_ISSUER_AUTHORIZATION_SETTINGS_NEXT as issuerCountryNext,
} from './actionTypes';

const defaultState = {
  countrySettings: {},
  isLoading: true,
  isError: false,
};

const GET_ISSUER_AUTHORIZATION_SETTINGS_NEXT = issuerCountryNext();

export const authorize = (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_ISSUER_AUTHORIZATION_SETTINGS_NEXT.INIT:
      return defaultState;
    case GET_ISSUER_AUTHORIZATION_SETTINGS_NEXT.SUCCESS:
      return {
        ...state,
        countrySettings: payload,
        isLoading: false,
        isError: false,
      };
    case GET_ISSUER_AUTHORIZATION_SETTINGS_NEXT.ERROR:
      return {
        ...state,
        countrySettings: {},
        isLoading: false,
        isError: true,
      };
    default:
      return state;
  }
};
