import { CLOSE_MODAL_SUCCESS, OPEN_MODAL_SUCCESS } from './actionTypes';
import { loginNext, logoutNext, sessionNext } from '../../auth/actionTypes';

const defaultState = {
  name: '',
  loading: false,
  isError: false,
  errorType: '',
};

export const modal = (state = defaultState, { type, payload }) => {
  switch (type) {
    case OPEN_MODAL_SUCCESS:
      return {
        ...state,
        name: payload.name,
      };

    case loginNext.INIT:
      return state.name === 'LoginWithTFA'
        ? {
          ...state,
          loading: true,
        } : state;

    case CLOSE_MODAL_SUCCESS:
    case logoutNext.SUCCESS:
    case sessionNext.ERROR:
      return defaultState;

    case loginNext.ERROR:
      return state.name === 'LoginWithTFA' ? {
        name: state.name,
        isError: true,
        errorType: payload.message,
        loading: false,
      } : state;

    default: return state;
  }
};
