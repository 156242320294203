import { GET_AGREEMENT_NEXT as next, GET_AGREEMENT_BY_PULL_NEXT as nextPull } from './actionTypes';

const defaultState = {
  isLoading: false,
  isError: false,
  data: {},
};
const GET_AGREEMENT_NEXT = next();
const GET_AGREEMENT_BY_PULL_NEXT = nextPull();

export const agreement = (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_AGREEMENT_NEXT.INIT:
      return {
        ...defaultState,
        isLoading: true,
      };

    case GET_AGREEMENT_NEXT.SUCCESS:
    case GET_AGREEMENT_BY_PULL_NEXT.SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
        isError: false,
      };

    case GET_AGREEMENT_NEXT.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    default:
      return state;
  }
};
