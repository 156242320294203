import { GET_OPPORTUNITY_DEPOSIT_ADDRESSES as next } from './actionTypes';

const defaultState = {
  isLoading: false,
  isError: false,
  data: [],
};
const GET_OPPORTUNITY_DEPOSIT_ADDRESSES = next();

export const depositAddresses = (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_OPPORTUNITY_DEPOSIT_ADDRESSES.INIT:
      return {
        ...state,
        isLoading: true,
      };

    case GET_OPPORTUNITY_DEPOSIT_ADDRESSES.SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
        isError: false,
      };

    case GET_OPPORTUNITY_DEPOSIT_ADDRESSES.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    default:
      return state;
  }
};
