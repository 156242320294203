import { GET_OPPORTUNITIES_NEXT } from './actionTypes';

const defaultState = {
  isLoading: false,
  isError: false,
  data: [],
};

export const opportunities = (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_OPPORTUNITIES_NEXT.INIT:
      return {
        ...state,
        isLoading: true,
      };

    case GET_OPPORTUNITIES_NEXT.SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
        isError: false,
      };

    case GET_OPPORTUNITIES_NEXT.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    default:
      return state;
  }
};
