import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { connectStore } from '@securitize/ui-components';
import rootReducer from './rootReducer';

const composeEnhancers = composeWithDevTools({
  name: 'dashboard',
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./rootReducer', () => store.replaceReducer(rootReducer));
}
connectStore(store);
export default store;
