import { getNotificationsNext, markNotificationReadNext } from './actionTypes';

const defaultState = {
  isInitialized: false,
  isLoading: false,
  isError: false,
  errorType: '',
  data: [],
  readNotifications: [],
};

export const notifications = (state = defaultState, { type, payload }) => {
  switch (type) {
    case getNotificationsNext.INIT:
      return {
        ...state,
        isLoading: true,
      };

    case getNotificationsNext.SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
        isInitialized: true,
        errorType: '',
        isError: false,
      };

    case markNotificationReadNext.SUCCESS:
      return {
        ...state,
        readNotifications: payload,
      };

    case getNotificationsNext.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorType: payload.message,
      };

    default:
      return state;
  }
};
