import { OPEN_MODAL_SUCCESS, SAVE_MODAL_OPTIONS } from './actionTypes';
import { loginNext } from '../../auth/actionTypes';

const defaultState = {};

export const options = (state = defaultState, { type, payload }) => {
  switch (type) {
    case SAVE_MODAL_OPTIONS:
      return {
        ...state,
        ...payload,
      };

    case OPEN_MODAL_SUCCESS:
      return {
        ...state,
        ...payload.options,
      };
    case loginNext.SUCCESS:
      return defaultState;

    default: return state;
  }
};
