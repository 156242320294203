import { GET_LIQUIDITY_PROVIDERS_NEXT } from './actionTypes';

const defaultState = {
  isLoading: true,
  isError: false,
  providers: [],
  airswap: {},
};

export const liquidityProviders = (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_LIQUIDITY_PROVIDERS_NEXT.INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case GET_LIQUIDITY_PROVIDERS_NEXT.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case GET_LIQUIDITY_PROVIDERS_NEXT.SUCCESS:
      return {
        ...state,
        airswap: payload.airswapConfig,
        providers: payload.providers,
        isLoading: false,
        isError: false,
      };
    default:
      return state;
  }
};
