import { GET_ISSUER_SETTINGS_NEXT } from './actionTypes';

const defaultState = {
  isLoading: true,
  isError: false,
  config: {},
  texts: {},
  variables: {},
};

export const issuerConfiguration = (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_ISSUER_SETTINGS_NEXT.INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case GET_ISSUER_SETTINGS_NEXT.ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    case GET_ISSUER_SETTINGS_NEXT.SUCCESS:
      return {
        ...state,
        isError: false,
        isLoading: false,
        config: payload.config || {},
        texts: payload.texts || {},
        variables: payload.variables || {},
      };

    default:
      return state;
  }
};
