import { getInvestorPortfolioNext } from './actionTypes';

const defaultState = {
  isInitialized: false,
  isLoading: false,
  isError: false,
  errorType: '',
  ownedTokens: [],
  investmentRequests: [],
  pendingAssets: [],
  hasTokensDeployed: false,
};

export const portfolio = (state = defaultState, { type, payload }) => {
  switch (type) {
    case getInvestorPortfolioNext.INIT:
      return {
        ...state,
        isLoading: true,
      };

    case getInvestorPortfolioNext.SUCCESS:
      return {
        ...state,
        ownedTokens: payload.tokens,
        investmentRequests: payload.investmentRequests,
        pendingAssets: payload.pendingAssets,
        hasTokensDeployed: payload.hasTokensDeployed,
        isLoading: false,
        isInitialized: true,
        errorType: '',
        isError: false,
      };

    case getInvestorPortfolioNext.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorType: payload.message,
      };

    default:
      return state;
  }
};
