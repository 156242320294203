import { GET_OPPORTUNITY_WIZARD_NEXT as next, RESET_OPPORTUNITY_WIZARD_ACTION } from './actionTypes';

const defaultState = {
  isLoading: false,
  isError: false,
  data: {},
};
const GET_OPPORTUNITY_WIZARD_NEXT = next();

export const opportunityWizard = (state = defaultState, { type, payload }) => {
  switch (type) {
    case RESET_OPPORTUNITY_WIZARD_ACTION:
      return { ...defaultState };
    case GET_OPPORTUNITY_WIZARD_NEXT.INIT:
      return {
        ...state,
        isLoading: true,
      };

    case GET_OPPORTUNITY_WIZARD_NEXT.SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
        isError: false,
      };

    case GET_OPPORTUNITY_WIZARD_NEXT.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    default:
      return state;
  }
};
