import { combineReducers } from 'redux';
import { auth } from './auth/reducer';
import { authorize } from './views/SystemPages/Authorize/reducer';
import { i18n } from './i18n/reducer';
import { helpers } from './utils/helpers/reducer';
import { issuerConfiguration } from './containers/DefaultLayout/reducer';
import { notifications } from './views/Pages/Notifications/reducer';
import { investment } from './views/Pages/Investment/reducer';
import { investorQualification } from './views/Pages/InvestorQualification/reducer';
import { portfolio } from './views/Pages/Portfolio/reducer';
import { documents } from './views/Pages/Documents/reducer';
import { tokenDetails } from './views/Pages/TokenDetails/reducer';
import { wallets } from './views/Pages/Portfolio/components/Wallets/reducer';
import { FAQ } from './views/SystemPages/FAQ/reducer';
import { liquidityProviders } from './views/Pages/Liquidity/reducer';

export default combineReducers({
  auth,
  authorize,
  notifications,
  i18n,
  helpers,
  issuerConfiguration,
  investorQualification,
  investment,
  portfolio,
  tokenDetails,
  documents,
  wallets,
  FAQ,
  liquidityProviders,
});
