import Cookies from 'js-cookie';
import { loginNext, logoutNext, sessionNext } from './actionTypes';
import { GET_QUALIFICATION_NEXT, SUBMIT_QUALIFICATION_QUESTIONS_NEXT } from '../views/Pages/InvestorQualification/actionTypes';
import { MixPanel } from '../utils/helpers/mixPanel';

const defaultState = {
  isLoading: false,
  isAuthenticated: false,
  isError: false,
  errorType: '',
  token: Cookies.get('auth-token') || null,
  user: null,
};

const identifyUser = (user) => {
  if (window.zE) {
    try {
      const userName = (user.investorType === 'individual' ? user.fullName : user.entityName) || '';
      if (typeof window.zE.identify === 'function') {
        window.zE.identify({
          name: userName,
          email: user.email,
          organization: (user.investorType === 'individual' ? 'individual' : user.entityName) || 'other',
        });
      } else {
        window.zE('webWidget', 'identify', {
          name: userName,
          email: user.email,
          organization: (user.investorType === 'individual' ? 'individual' : user.entityName) || 'other',
        });
      }
      window.zE('webWidget', 'prefill', {
        name: {
          value: userName,
          readOnly: !!userName, // optional
        },
        email: {
          value: user.email,
          readOnly: !!user.email, // optional
        },
        organization: {
          value: (user.investorType === 'individual' ? 'individual' : user.entityName) || 'other',
          readOnly: true, // optional
        },
      });
    } catch (e) {
      console.error(e);
    }
  }
};

export const auth = (state = defaultState, { type, payload }) => {
  switch (type) {
    case loginNext.INIT:
    case logoutNext.INIT:
    case sessionNext.INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorType: '',
      };
    case loginNext.ERROR:
    case sessionNext.ERROR:
      if (payload.statusCode === 401) Cookies.remove('auth-token');
      return {
        ...state,
        isError: true,
        errorType: payload.statusCode === 401 ? 'unauthorized' : 'server-error',
        isAuthenticated: false,
        token: null,
        user: null,
        isLoading: false,
      };
    case loginNext.SUCCESS:
    case sessionNext.SUCCESS:
      Cookies.set('auth-token', payload.token, { expires: 7, path: '/' });
      if (payload.user.investorId) {
        identifyUser(payload.user);
        MixPanel.identify(payload.user.mixPanelId);
      }
      return {
        ...state,
        isError: false,
        errorType: '',
        isAuthenticated: true,
        token: payload.token,
        user: payload.user,
        isLoading: true, // Keep waiting until Qualification data is obtained
      };

    case logoutNext.ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case logoutNext.SUCCESS:
      Cookies.remove('auth-token');
      return defaultState;
    case SUBMIT_QUALIFICATION_QUESTIONS_NEXT.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          isAccredited: payload.isAccredited,
        },
      };
    case GET_QUALIFICATION_NEXT.INIT:
      return {
        ...state,
        isLoading: true,
      };
    case GET_QUALIFICATION_NEXT.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          isAccredited: payload.isAccredited,
        },
        isLoading: false,
      };
    case GET_QUALIFICATION_NEXT.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    default:
      return state;
  }
};
